<template>
    <div class="top-bar" tabindex="-1">
        <div @click="goHome" class="top-bar-logo-td">
            <img class="top-bar-logo" src="@/assets/top-bar/logo-gsd-2-letras.svg"/>
        </div>

        <nav class="top-bar-menu-group">
            <ul class="top-bar-menu-list">
                <li class="menu-item-group">
                    <router-link class="menu-item" :class="{ 'router-link-active' : page === 'home' }" :title="$t('Home')" :to="{ name: 'home' }">
                        <div class="menu-container">
                            <p class="menu-item-text">{{ $t("Home") }}</p>
                        </div>
                    </router-link>
                </li>
                <li class="menu-item-group">
                    <router-link class="menu-item" :class="{ 'router-link-active': (page === 'marketplace' || page === 'auctions' || page === 'sales' || page === 'create-auction' || page === 'put-on-sale') }" :title="$t('Marketplace')" :to="{ name: 'marketplace' }">
                        <div class="menu-container">
                            <p class="menu-item-text">{{ $t("Marketplace") }}</p>
                        </div>
                    </router-link>
                </li>
                <li class="menu-item-group">
                    <router-link class="menu-item" :class="{ 'router-link-active': page === 'transactions' || page === 'transaction' }" :title="$t('Transactions')" :to="{ name: 'transactions' }">
                        <div class="menu-container">
                            <p class="menu-item-text">{{ $t("Transactions") }}</p>
                        </div>
                    </router-link>
                </li>
                <li class="menu-item-group">
                    <router-link class="menu-item" :class="{ 'router-link-active': page === 'analytics' }" :title="$t('Analytics')" :to="{ name: 'analytics' }">
                        <div class="menu-container">
                            <p class="menu-item-text">{{ $t("Analytics") }}</p>
                        </div>
                    </router-link>
                </li>
                <li class="menu-item-group no-border">
                    <router-link class="menu-item" :class="{ 'router-link-active': page === 'clinical-trials' || page === 'clinical-trial' }" :title="$t('Clinical Trials')" :to="{ name: 'clinical-trials' }">
                        <div class="menu-container">
                            <p class="menu-item-text">{{ $t("Clinical Trials") }}</p>
                        </div>
                    </router-link>
                </li>
            </ul>
        </nav>

        <div class="top-bar-user-td" v-if="loggedIn">
            <button type="button" class="top-bar-button-balance" :title="$t('Balance')" @click="openBalance">
                {{ balance }}
            </button>
            <button type="button" class="top-bar-button" :title="$t('Notifications')" @click="openNotifications">
                <div v-if="hasNotifications" class="has-notifications"></div>
                <img class="notification-image" src="@/assets/top-bar/notification.png"/>
            </button>
            <div class="top-bar-button-bar"></div>
            <button type="button" class="top-bar-button" :title="$t('Select your language')" @click="selectLanguage">
                <img class="translate-image" src="@/assets/top-bar/translate.png"/>
            </button>
            <button type="button" class="top-bar-button-img" :title="$t('User settings')" @click="openUserSettings">
                <img v-if="profileImage" class="btn-image" :src="profileImage" />
                <img v-else class="btn-image" src="@/assets/user.png" />
            </button>
        </div>

        <div class="top-bar-user-td" v-if="!loggedIn">
            <button type="button" class="top-bar-button" :title="$t('Select your language')" @click="selectLanguage">
                <i class="fas fa-language"></i>
            </button>
            <button type="button" class="top-bar-button" :title="$t('Change theme')" @click="invertTheme">
                <i v-if="theme === 'dark'" class="fas fa-sun"></i>
                <i v-else class="fas fa-moon"></i>
            </button>
            <button type="button" @click="login" class="btn btn-primary btn-top-bar-login">
                {{ $t("Login") }}
            </button>
        </div>
    </div>
    <div class="topbar-submenu" tabindex="-1">
        <nav class="top-bar-menu-group-submenu">
            <ul class="top-bar-menu-list-submenu">
                <li class="menu-item-group">
                    <router-link class="menu-item" :class="{ selected: page === 'home' }" :title="$t('Home')" :to="{ name: 'home' }">
                        <div class="menu-container">
                            <p class="menu-item-text">{{ $t("Home") }}</p>
                        </div>
                    </router-link>
                </li>
                <li class="menu-item-group">
                    <router-link class="menu-item" :class="{ selected: page === 'marketplace' || page === 'auctions' || page === 'sales' || page === 'create-auction' || page === 'put-on-sale' }" :title="$t('Marketplace')" :to="{ name: 'marketplace' }">
                        <div class="menu-container">
                            <p class="menu-item-text">{{ $t("Marketplace") }}</p>
                        </div>
                    </router-link>
                </li>
                <li class="menu-item-group">
                    <router-link class="menu-item" :class="{ selected: page === 'transactions' }" :title="$t('Transactions')" :to="{ name: 'transactions' }">
                        <div class="menu-container">
                            <p class="menu-item-text">{{ $t("Transactions") }}</p>
                        </div>
                    </router-link>
                </li>
                <li class="menu-item-group">
                    <router-link class="menu-item" :class="{ selected: page === 'analytics' }" :title="$t('Analytics')" :to="{ name: 'analytics' }">
                        <div class="menu-container">
                            <p class="menu-item-text">{{ $t("Analytics") }}</p>
                        </div>
                    </router-link>
                </li>
                <li class="menu-item-group no-border">
                    <router-link class="menu-item" :class="{ selected: page === 'clinical-trials' }" :title="$t('Clinical Trials')" :to="{ name: 'clinical-trials' }">
                        <div class="menu-container">
                            <p class="menu-item-text">{{ $t("Clinical Trials") }}</p>
                        </div>
                    </router-link>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script lang="ts">
import { ColorThemeName, getTheme, setTheme, getCurrency } from "@/control/app-preferences";
import { AuthController } from "@/control/auth";
import { defineComponent } from "vue";
import { Request } from "@asanrom/request-browser";
import { Timeouts } from "@/utils/timeout";
import { getUniqueStringId } from "@/utils/unique-id";
import { ApiBalance } from "@/api/api-group-balance";
import { NotificationsController } from "@/control/notifications";

export default defineComponent({
    components: {},
    name: "TopBar",
    emits: ["openModal"],
    setup: function() {
        return {
            loadRequestId: getUniqueStringId(),
        };
    },
    data: function () {
        return {
            page: 'home',
            platformName: import.meta.env.VITE__PLATFORM_NAME || "Platform",
            loggedIn: AuthController.isAuthenticated(),
            profileImage: AuthController.ProfileImage,
            theme: getTheme(),
            profileName: AuthController.ProfileName || AuthController.Username || "",

            balance: "",
            currency: getCurrency(),

            hasNotifications: NotificationsController.UnreadCount > 0,
        };
    },
    methods: {
        updatePage: function () {
            this.page = this.$route ? (this.$route.name as string) : '';
        },

        openUserSettings: function () {
            this.$emit("openModal", "account-settings");
        },

        openNotifications: function () {
            this.$emit("openModal", "notifications");
        },

        openBalance: function() {
            this.$emit("openModal", "balance-open");
        },

        selectLanguage: function () {
            this.$emit("openModal", "change-language-modal");
        },

        invertTheme: function () {
            setTheme(this.theme === "dark" ? "light" : "dark");
        },

        login: function () {
            this.$requireLogin();
        },

        onAuthChanged: function () {
            this.loggedIn = AuthController.isAuthenticated();
            this.profileName = AuthController.ProfileName || AuthController.Username || "";
            this.profileImage = AuthController.ProfileImage;
            this.refresh();
        },

        onThemeChanged: function (t: ColorThemeName) {
            this.theme = t;
        },

        refresh: function() {
            if(this.loggedIn) {
                this.currency = getCurrency();
                this.loadBalance();
            }
        },

        loadBalance: function() {
            Timeouts.Abort(this.loadRequestId);
            Request.Abort(this.loadRequestId);

            Request.Pending(this.loadRequestId, ApiBalance.GetPaymentBalance({ currency: this.currency }))
                .onSuccess((res) => {
                    this.balance = res.balance;
                })
                .onRequestError((err, handleErr) => {
                    handleErr(err, {
                        unauthorized: () => {
                            this.balance = "";
                        },
                        notFound: () => {
                            this.balance = "";
                        },
                        temporalError: () => {
                            // Retry
                            Timeouts.Set(this.loadRequestId, 1500, this.loadBalance.bind(this));
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    console.error(err);
                    // Retry
                    Timeouts.Set(this.loadRequestId, 1500, this.loadBalance.bind(this));
                });
        },

        onNotificationsChanged: function () {
            this.hasNotifications = NotificationsController.UnreadCount > 0;
        },  

        goHome: function() {
            this.$router.push({ name: "home" });
        }
    },
    mounted: function () {
        this.$listenOnAppEvent("balance-changed", this.refresh.bind(this));
        this.$listenOnAppEvent("currency-changed", this.refresh.bind(this));
        this.$listenOnAppEvent("auth-status-changed", this.onAuthChanged.bind(this));
        this.$listenOnAppEvent("theme-changed", this.onThemeChanged.bind(this));
        this.$listenOnAppEvent("notifications-status-changed", this.onNotificationsChanged.bind(this));
        this.updatePage();
    },
    beforeUnmount: function () {},
    watch: {
        $route: function () {
            this.updatePage();
        },
    },
});
</script>

<style></style>
